import React from 'react'

import { Link } from 'gatsby'

const MenuObiettivi = () => (
<li id='objNav' className='nav-item dropdown'>

    <button className='nav-link dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' id='objDropdown'>Obiettivi</button>
    <ul className='dropdown-menu' aria-labelledby='objDropdown'>
        <li><Link to='/obiettivi/#' className='dropdown-item'>Obiettivi</Link></li>
        <li className='dropdown-submenu'><button className='dropdown-item dropdown-toggle'>Pianificazione</button>
            <ul className='dropdown-menu'>
                <li><Link to='/pianificazione' className='dropdown-item'>Livello maturità</Link></li>
                <li><Link to='/pianificazione' className='dropdown-item'>Implementazione</Link></li>
            </ul>
        </li>
    </ul>
</li>
)

export default MenuObiettivi
