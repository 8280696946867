import React from 'react'

import { Link } from 'gatsby'

const MenuTeam = () => (
<li id='teamNav' className='nav-item dropdown'>
    {/* <a className="nav-link dropdown-toggle" target="_self" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="teamDropdown" href="#">
        Team
    </a> */}

    <button className='nav-link dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' id='teamDropdown'>Team</button>
    <ul className='dropdown-menu' aria-labelledby='teamDropdown'>
        <li><Link to='/team/#dev' className='dropdown-item'>Team sviluppo</Link></li>
        <li><Link to='/team/#controllo' className='dropdown-item'>Comitato di verifica</Link></li>
        <li><Link to='/team/#organigramma' className='dropdown-item'>Organigramma</Link></li>
    </ul>
</li>
)

export default MenuTeam
