import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'

const Contact = props => (
<Col md={3} xs={12} className='fh5co-footer-link'>
    <div className='alt'>
        <strong>{props.name}</strong>
        <div>
            <br />{props.dep}
            <br /> <br />
        </div>
        <div>
            <br /> {props.inst}
        </div>
        <div>
            <br /> {props.add}
            <br /> {props.cap}
        </div>
        <div>
            <br /> T {props.tel}
            <br /> {props.mail}
        </div>
    </div>
</Col>
)

Contact.defaultProps = {
    name: 'en',
    dep: '',
    inst: '',
    add: '',
    cap: '',
    tel: '',
    mail: ''
}

Contact.propTypes = {
    name: PropTypes.string,
    dep: PropTypes.string,
    inst: PropTypes.string,
    add: PropTypes.string,
    cap: PropTypes.string,
    tel: PropTypes.string,
    mail: PropTypes.string
}

export default Contact
