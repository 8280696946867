import React from 'react'
import PropTypes from 'prop-types'
import SEO from './seo'
import SigridNavbar from './navbar/sigridNavbar'
import SigridFooter from './footer/sigridFooter'

class Layout extends React.Component {

  componentDidMount() {

    if (this.props.frame === undefined) {
      return;
    }

    const elem = document.getElementById(this.props.frame);
    if (elem !== undefined) {
      elem.classList.add('active');
    }
  }

  render() {
    return (
      <>
        <SEO title='SIGRId - IAS' lang='it' />
        <SigridNavbar />

        <div id='fh5co-page'>
          {this.props.children}
        </div>

        <SigridFooter />
      </>
    )
  }
}

Layout.propTypes = {
  frame: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

export default Layout
