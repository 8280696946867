import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

import Contact from './contact'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import iasLogo from '../../images/IAS_logo.png'
import supsiLogo from '../../images/logo_SUPSI_15mm_ITA_neg.png'

const SigridFooter = () => (

<div id='footer' className='footer'>
    <Container>
        <Row className='row row-bottom-padded-md row-eq-height'>

            <div className='col-md-12 fh5co-footer-link'>
            <h3>Contatti</h3>
            </div>

            <Contact
              name='IAS' dep='Istituto delle assicurazioni sociali' inst='Servizio del controllo interno e della gestione dei rischi'
              add='Via Ghiringhelli 15a' cap='CH-6500 Bellinzona' tel='+41 (0)00 000 00 00' mail='mail@mail.ch'
            />

            <Contact
              name='SUPSI - DEASS' dep='Dipartimento Economia Aziendale Sanità e Sociale' inst='Centro competenze management e imprenditorialità'
              add='Palazzo E' cap='CH-6928 Manno' tel='+41 (0)00 000 00 00' mail='mail@mail.ch'
            />

            <Contact
              name='SUPSI - DACD' dep='Dipartimento Ambiente Costruzioni e Design' inst='Istituto scienze della Terra'
              add='Campus Trevano, Via Trevano' cap='CH-6952 Canobbio' tel='+41 (0)00 000 00 00' mail='mail@mail.ch'
            />

            <div className='col-md-3 col-xs-12 fh5co-footer-link'>
                <a target='_blank' href='https://www4.ti.ch/dss/ias/ias/' rel='noopener noreferrer' aria-label='IAS'>
                    <Image src={iasLogo} alt='' fluid />
                </a>
                <p />
                <a target='_blank' href='http://www.supsi.ch/home.html' rel='noopener noreferrer' aria-label='SUPSI'>
                    <Image src={supsiLogo} alt='' fluid />
                </a>
            </div>

        </Row>

        <Row>
            <div className='col-md-6 offset-md-3 col-xs-12 text-center'>
                <p>Copyright 2016 Free Html5
                    Module. All Rights Reserved.
                    <br />Made with <FontAwesomeIcon icon={faHeart} /> by
                    <a href='http://freehtml5.co/' target='_blank' rel='noopener noreferrer'>Freehtml5.co</a>
                </p>
            </div>
        </Row>

    </Container>
</div>

)

export default SigridFooter
