import React from 'react'

import { Link } from 'gatsby'

const MenuPianificazione = () => (
<li id='projNav' className='nav-item dropdown'>

    <button className='nav-link dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' id='projDropdown'>Progetto</button>
    <ul className='dropdown-menu' aria-labelledby='projDropdown'>
        <li><Link to='/progetto/#sigrid' className='dropdown-item'>SIGRId</Link></li>
        <li><Link to='/progetto/#convenzione' className='dropdown-item'>Convenzione</Link></li>
        <li><Link to='/progetto/#elem' className='dropdown-item'>Elementi del sistema</Link></li>
    </ul>
</li>
)

export default MenuPianificazione
