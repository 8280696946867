import React from 'react'

import { Link } from 'gatsby'

const MenuApprofondimenti = () => (
<li id='appNav' className='nav-item dropdown'>

    <button className='nav-link dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' id='appDropdown'>Approfondimenti</button>
    <ul className='dropdown-menu' aria-labelledby='appDropdown'>
        <li className='dropdown-submenu'><button className='dropdown-item dropdown-toggle'>Approfondimenti SCI</button>
            <ul className='dropdown-menu'>

                <li className='dropdown-submenu-left'><button className='dropdown-item dropdown-toggle'>Gestione rischi</button>
                    <ul className='dropdown-menu'>
                        <li><Link to='/app/rischi/' className='dropdown-item'>Gestione rischi</Link></li>
                        <li><Link to='/404' className='dropdown-item'>Letteratura</Link></li>
                    </ul>
                </li>

                <li className='dropdown-submenu-left'><button className='dropdown-item dropdown-toggle'>Sistema controllo interno</button>
                    <ul className='dropdown-menu'>
                        <li><Link to='/app/sci' className='dropdown-item'>SCI</Link></li>
                        <li className='dropdown-submenu-left'><button className='dropdown-item dropdown-toggle'>Architettura del sistema</button>
                            <ul className='dropdown-menu'>
                                <li><Link to='/app/architettura' className='dropdown-item'>Architettura del sistema</Link></li>
                                <li><Link to='/app/architettura/#gerarchia' className='dropdown-item'>Gerarchia dei processi</Link></li>
                                <li><Link to='/app/architettura/#ordine' className='dropdown-item'>Ordine di appartenenza</Link></li>
                                <li><Link to='/app/architettura/#codifica' className='dropdown-item'>Codifica</Link></li>
                                <li><Link to='/app/architettura/#configurazione' className='dropdown-item'>Configurazione del sistema</Link></li>
                            </ul>
                        </li>
                        <li className='dropdown-submenu-left'><button className='dropdown-item dropdown-toggle'>Leggi di riferimento</button>
                            <ul className='dropdown-menu'>
                                <li><Link to='/app/leggi/#leggio' className='dropdown-item'>Codice obbligazioni</Link></li>
                                <li><Link to='/app/leggi/#leggif' className='dropdown-item'>Diritto federale</Link></li>
                                <li><Link to='/app/leggi/#leggic' className='dropdown-item'>Diritto cantonale</Link></li>
                            </ul>
                        </li>

                        <li><Link to='/app/revisione' className='dropdown-item'>Standard revisione</Link></li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</li>
)

export default MenuApprofondimenti
