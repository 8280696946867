import { Link } from 'gatsby'
// import PropTypes from "prop-types"
import React from 'react'
import { Container, Navbar, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import MenuApprofondimenti from './menuApprofondimenti'
import MenuObiettivi from './menuObiettivi';
import MenuTeam from './menuTeam';
import MenuProgetto from './menuProgetto';

import iasLogo from '../../images/IAS_logo.png'
import supsiLogo from '../../images/logo_SUPSI_15mm_ITA_neg.png'

class SigridNavbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    render() {
        return (
            <nav className='navbar navbar-expand-md fixed-top fh5co-header-section'>
                <Container id='container-nav'>

                    <Link to='/' style={{ fontSize: '21px' }}>SIGRId</Link>
                    <Button onClick={() => this.setState({ open: !this.state.open })} aria-controls='navbarNavDropdown' aria-expanded={this.state.open} className='navbar-toggler nav-button collapsed'>
                        <FontAwesomeIcon icon={faBars} className='nab-button' />
                    </Button>

                    <Navbar.Collapse in={this.state.open} id='navbarNavDropdown'>
                        <ul className='navbar-nav ml-auto my-2'>
                            <MenuProgetto />
                            <MenuObiettivi />
                            <MenuTeam />
                            <li id='docNav' className='nav-item dropdown'>
                                <Link className='nav-link' to='/risultati'>Risultati</Link>
                            </li>
                            <MenuApprofondimenti />
                            <li>
                                <a target='_blank' href='https://www4.ti.ch/dss/ias/ias/' rel='noopener noreferrer'>
                                    <img src={iasLogo} className='navbar-img' alt='' />
                                </a>
                                <a target='_blank' href='http://www.supsi.ch/home.html' rel='noopener noreferrer'>
                                    <img src={supsiLogo} className='navbar-img' alt='' />
                                </a>
                            </li>
                        </ul>

                    </Navbar.Collapse>
                </Container>
            </nav>)
    }
}

export default SigridNavbar
