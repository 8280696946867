import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-bootstrap'

const Section = (props) => (
    <div id={props.id} className='fh5co-section'>
        {props.children}
    </div>
)

Section.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

const SingleSection = (props) => (
    <div id={props.id} className='fh5co-section'>
        <Container>
            <Row>
                {props.children}
            </Row>
        </Container>

    </div>
)

SingleSection.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

const SectionGray = (props) => (
    <div id={props.id} className='fh5co-section-gray'>
        {props.children}
    </div>
)

SectionGray.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

const SingleSectionGray = (props) => (
    <div id={props.id} className='fh5co-section-gray'>
        <Container>
            <Row>
                {props.children}
            </Row>
        </Container>
    </div>
)

SingleSectionGray.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

export { Section, SectionGray, SingleSection, SingleSectionGray }
